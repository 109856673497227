<template>
  <div class="content">
    <h1>Страница входа</h1>
    <tabs class="link-tabs">
      <tab name="Вход">
        <h2 class="form-title">Вход</h2>
        <form class="login-form" @submit.prevent="login">
          <label for="email">Введите имя:</label>
          <input
            type="text"
            name="name"
            id="name"
            v-model="loginName"
            required
          />
          <label for="password">Введите пароль:</label>
          <input
            type="password"
            name="password"
            id="password"
            v-model="loginPass"
            required
          />
          <button type="submit" class="radio-block__button">Войти</button>
        </form>
      </tab>
      <tab name="Регистрация">
        <h2 class="form-title">Регистрация</h2>
        <form class="login-form" @submit.prevent="registration">
          <label for="name">Имя:</label>
          <input type="text" id="name" name="name" v-model="name" />
          <!-- <label for="email">Введите email:</label>
          <input type="text" name="email" id="email" v-model="email" required /> -->
          <label for="password">Введите пароль:</label>
          <input
            type="password"
            name="password"
            id="password"
            v-model="password"
            required
          />
          <button type="submit" class="radio-block__button">
            Зарегистрироваться
          </button>
        </form>
      </tab>
    </tabs>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      email: "",
      name: "",
      password: "",
      loginName: "",
      loginPass: "",
      data: '',
    };
  },
  methods: {
    async login() {
      try {
        await axios({
          method: "POST",
          url: "http://localhost:3300/login",
          data: {
            name: this.loginName,
            password: this.loginPass,
          },
        });

        const response = await axios.get('http://localhost:3300/userdata');
        this.data = response.data;
        localStorage.setItem('data', JSON.stringify(response.data));
        console.log(this.data);
      } catch (error) {
        console.error(error);
      }
    },

    async registration() {
      await axios({
        method: "POST",
        url: "http://localhost:3300/register",
        data: {
          name: this.name,
          password: this.password,
        },
      });
    },
  },

  created() {
    document.title = "Страница входа";
  },
  mounted() {
    console.log(window.sessionStorage);
  },
};
</script>

<style lang="scss">
.login-form {
  display: flex;
  flex-direction: column;
  max-width: 30%;
  margin: auto;

  label {
    color: #000;
  }

  input {
    border: none;
    border-bottom: 2px solid #1c7fe2;
    background-color: transparent;
    margin: 2rem 0;
    padding: 12px;
    font-size: 1rem;
  }
}
.form-title {
  text-align: center;
  margin-bottom: 2rem;
}
</style>